import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

// Combined query for both site metadata and URL
const detailsQuery = graphql`
  query CombinedSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`

const SEO = ({ description, lang = 'en', meta = [], keywords = [], title, isHomePage }) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description
        const pageTitle = isHomePage
          ? `${data.site.siteMetadata.title}`
          : `%s | ${data.site.siteMetadata.title}`
        const currentUrl = `${data.site.siteMetadata.siteUrl}${typeof window !== 'undefined' ? window.location.pathname : ''}`
            
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={pageTitle}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: `image`,
                content: `https://cdn.emersoft.co/emersoft-website/logos/emersoft-1200x1200.jpg`,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:image`,
                content: `https://cdn.emersoft.co/emersoft-website/logos/emersoft-1200x1200.jpg`,
              },
              {
                property: `og:url`,
                content: currentUrl,
              },
              {
                property: `og:site_name`,
                content: `Emersoft`,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:locale`,
                content: `en_GB`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:site`,
                content: `@emersoft_ltd`,
              },
              {
                name: `twitter:creator`,
                content: `@emersoft_ltd`,
              },
              {
                name: `twitter:image:src`,
                content: `https://cdn.emersoft.co/emersoft-website/logos/emersoft-1200x1200.jpg`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `author`,
                content: `Emersoft`,
              },
            ]
              .concat(
              keywords.length > 0
                ? {
                    name: `keywords`,
                    content: keywords.join(`, `),
                  }
                : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  isHomePage: PropTypes.bool,
}

export default SEO